import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

const state =  {
  currentUser: null,
  token: null
}

const vuexPersist = new VuexPersist({
  key: 'portal-calvanico-cpa',
  storage: window.localStorage
})

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins: [vuexPersist.plugin]
})