import Vue from 'vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'

import store from '@/store'

// Pages
import LoginContainer from '@/containers/LoginContainer'

Vue.use(VueRouter)
Vue.use(VueResource)

Vue.http.options.root = process.env.VUE_APP_API_URL

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login/:isRedirect?',
    name: 'login',
    component: LoginContainer,
    meta: { auth: false }
  },
  {
    path: '/upload',
    name: 'assetUpload',
    component:() => import(/* webpackChunkName: "my-documents" */ '../containers/AssetUploadContainer.vue'),
    meta: { auth: true  }
  },
  {
    path: '/my-documents',
    name: 'myDocuments',
    component:() => import(/* webpackChunkName: "my-documents" */ '../containers/AssetMyDocumentsContainer.vue'),
    meta: { auth: true }
  },
  {
    path: '/inbox',
    name: 'inbox',
    component:() => import(/* webpackChunkName: "my-documents" */ '../containers/AssetInboxContainer.vue'),
    meta: { auth: true }
  },
  {
    path: '/invite',
    name: 'invite',
    component:() => import(/* webpackChunkName: "invite" */ '../containers/UserInvitesContainer.vue'),
    meta: { auth: true }
  },
  {
    path: '/register/:token',
    name: 'register',
    component:() => import(/* webpackChunkName: "register" */ '../containers/UserRegisterContainer.vue'),
    meta: { auth: false }
  },
  {
    path:'/confirm-email/:token',
    name: 'confirmEmail',
    component:() => import(/* webpackChunkName: "user-admin" */ '../containers/UserConfirmEmailContainer.vue'),
    meta: { auth: false }
  },
  {
    path: '/user/:userId?',
    name: 'user',
    component:() => import(/* webpackChunkName: "user-admin" */ '../containers/UserContainer.vue'),
    meta: { auth: true }
  },
  {
    path: '/users/:isSuccess?',
    name: 'users',
    component:() => import(/* webpackChunkName: "user-admin" */ '../containers/UsersContainer.vue'),
    meta: { auth: true }
  },
  {
    path: '/add-user',
    name: 'userAdd',
    component:() => import(/* webpackChunkName: "user-admin" */ '../containers/UserAddContainer.vue'),
    meta: { auth: true }
  },
  {
    path: '/edit-user/:userId',
    name: 'userEdit',
    component: () => import(/* webpackChunkName: "user-admin" */ '../containers/UserEditContainer.vue'),
    meta: { auth: true }
  },
  {
    path: '/password-reset/:token',
    name: 'passwordReset',
    component:() => import(/* webpackChunkName: "password-reset" */ '../containers/UserPasswordResetContainer.vue'),
    meta: { auth: false }
  },
  {
    path: '/password-reset-request',
    name: 'passwordResetRequest',
    component:() => import(/* webpackChunkName: "password-reset" */ '../containers/UserPasswordResetRequestContainer.vue'),
    meta: { auth: false }
  },
  {
    path: '/admin-share/:userId?',
    name: 'adminShare',
    component:() => import(/* webpackChunkName: "asset-upload" */ '../containers/AdminShareAssetsContainer.vue'),
    meta: { auth: true }
  },
  {
    path: '/admin-share-upload/:userId?',
    name: 'adminShareUpload',
    component:() => import(/* webpackChunkName: "admin" */ '../containers/AdminShareUploadContainer.vue'),
    meta: { auth: true }
  },
  {
    path: '/admin-user-assets/:userId?',
    name: 'adminUserAssets',
    component:() => import(/* webpackChunkName: "admin" */ '../containers/AdminUserAssetsContainer.vue'),
    meta: { auth: true }
  },
  {
    path: '/admin-emails',
    name: 'adminEmails',
    component:() => import(/* webpackChunkName: "admin" */ '../containers/AdminEmailsContainer.vue'),
    meta: { auth: true }
  },
  {
    path: '/admin-settings',
    name: 'adminSettings',
    component: () => import(/* webpackChunkName: "admin" */ '../containers/AdminSettingsContainer.vue'),
    meta: { auth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  hashbang: false,
  base: process.env.BASE_URL,
  routes
})

// Redirect routes to login screen if not authenticated
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth) && !store.getters.isAuthenticated) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else {
    next()
  }
})

Vue.router = router

// Add token to every request
Vue.http.interceptors.push(function (request, next) {
  request.headers.set('Authorization', 'Bearer ' + store.getters.token)
  next(res => {
    if (res.status === 401) {
      router.push({ name: 'login', params: { isRedirect: 1 } })
    }
  })
})

export default router
