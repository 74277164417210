<template>
  <div id="app">
    <banner></banner>
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner'

export default {
  name: 'app',
  components: {
    Banner
  }
}
</script>

<style>
@import'~font-awesome/css/font-awesome.min.css';
@import'./assets/site.css';
</style>
