<template>
  <div class="row">
    <div class="col-lg-4"></div>
    <div class="col-lg-4">
      <div class="card">
        <article class="card-body">
          <h4 class="card-title text-center mb-4 mt-1">Sign In</h4>
          <hr />
          <div class="alert alert-info" role="alert" v-if="getIsRedirectParam() && !isError">
            You have been logged out due to inactivity. Please sign in below.
          </div>
          <div class="alert alert-success text-center" role="alert" v-if="getIsPasswordChangeParam() && !isError">
            Password change successful. <br> Please sign in below.
          </div>
          <div class="alert alert-danger text-center" role="alert" v-if="isError">
            Invalid email or password.
          </div>
          <div class="alert alert-danger text-center" role="alert" v-if="isLockout">
            This account has been locked due too many login attempts. Please contact Carl Calvanico for assistance.
          </div>
          <div class="alert alert-danger text-center" role="alert" v-if="isDisabled">
            This account has been disabled. Please contact Carl Calvanico for assistance.
          </div>
          <div class="alert alert-danger text-center" role="alert" v-if="isEmailNotConfirmedError">
            An email has been sent to confirm this account. If you do not receive an email within 10 minutes please contact Carl Calvanico for assistance. Please check your spam folder.
          </div>
          <div class="alert alert-success text-center" role="alert" v-if="getIsConfirmEmailSuccess() && !isError">
            Your email has successfully been confirmed. Please sign in below.
          </div>
          <form @submit.prevent="onSubmit">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-user"></i>
                  </span>
                </div>
                <input class="form-control" placeholder="Email" type="email" v-model="email" v-on:keyup.enter="submit" v-bind:disabled="isLoading">
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
                <input class="form-control" placeholder="******" type="password" v-model="password" v-on:keyup.enter="submit" v-bind:disabled="isLoading">
              </div>
            </div>
            <div class="form-group text-center">
              <button type="submit" class="btn btn-primary btn-block" v-if="!isLoading">Login</button>
              <LoadingPanel v-if="isLoading"></LoadingPanel>
            </div>
            <p class="text-center" v-if="!isLoading">
              <a href="/password-reset-request">Forgot password?</a>
            </p>
          </form>
        </article>
      </div>
    </div>
    <div class="col-lg-4"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingPanel from "@/components/LoadingPanel";
import { default as usrAPI } from "@/api/UsersAPI";

export default {
  name: "LoginContainer",

  components: {
    'LoadingPanel': LoadingPanel
  },

  data () {
    return {
      email: "",
      password: "",
      isLoading: false,
      isError: false,
      isLockout: false,
      isDisabled: false,
      isEmailNotConfirmedError: false
    };
  },

  mounted () {
   this.logout()
  },

  methods: {
    ...mapGetters([
      'isAuthenticated',
      'isAdminUser'
    ]),

    ...mapActions([
      'login',
      'logout'
    ]),

    onSubmit: function() {
      this.isLoading = true
      this.isError = false
      this.isLockout = false
      this.isDisabled = false
      this.isEmailNotConfirmedError = false
      usrAPI
        .login(this.email, this.password)
        .then(response => {
          if (response) {
            this.login(response)
            this.redirectToHome()
          } else {
            this.logout()
          }
        })
        .catch((response) => {
          if (+response.status == 423) {
            this.isLockout = true
          } else if (+response.status == 412) {
            this.isDisabled = true
          } else if (+response.status == 403) {
            this.isEmailNotConfirmedError= true
          } else if (+response.status == 409) {
            this.redirectToPasswordReset(response.body)
          } else {
            console.log(JSON.stringify(response))
            this.isError = true
          }
        })
        .finally(() => {
          this.isLoading = false
        });
    },

    redirectToHome: function() {
       this.isAdminUser() 
        ? this.$router.push({ name: 'users' })
        : this.$router.push({ name: 'myDocuments' })
    },

    redirectToPasswordReset(resetToken) {
      this.$router.push({ name: "passwordReset", params: { token: resetToken }})
    },
    
    getIsRedirectParam: function() {
      return +this.$route.params.isRedirect == 1
    },

    getIsPasswordChangeParam: function() {
      return +this.$route.params.isRedirect == 2
    },

    getIsConfirmEmailSuccess: function() {
      return +this.$route.params.isRedirect == 3
    }
  }
};
</script>

<style scoped>
/* Larger than tablet */
@media (min-width: 750px) {
  .restrict-width {
    width: 350px;
  }
}
</style>
