import dayjs from 'dayjs'

function utcToLocalTimeString(dateTime) {
  const offset = new Date().getTimezoneOffset() / 60;
  const localDate = dayjs(dateTime).subtract(offset, 'hour')
  return localDate.isAfter('2000-01-01') ? localDate.format('MM/DD/YYYY hh:mm A') : ''
}

function dateToString(dateTime) {
  const date = dayjs(dateTime)
  return date.format('MM/DD/YYYY hh:mm a')
}

function localTimeToUtc(dateTime) {
  const offset = new Date().getTimezoneOffset() / 60;
  const localDate = dayjs(dateTime).add(offset, 'hour')
  return localDate.isAfter('2000-01-01') ? localDate.format('MM/DD/YYYY hh:mm A') : ''
}

function addHours(dateTime, hours) {
  const date = dayjs(dateTime).add(hours, 'hour')
  return date.isAfter('2000-01-01') ? date.format('MM/DD/YYYY hh:mm A') : ''
}

function setSuccessMessage (instance, prop) {
  instance[prop] = 'Success'
  setTimeout(() => {
    instance[prop] = null
  }, 3000)
}

function setErrorMessage (instance, prop) {
  instance[prop] = 'An Error Occured'
  setTimeout(() => {
    instance[prop] = null
  }, 3000)
}

function cloneObject (input) {
  return JSON.parse(JSON.stringify(input))
}

function forceFileDownload(response, fileName) {
  let blob = new Blob([response.data], {type: response.headers['content-type']})
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  document.body.appendChild(link)
  link.click()
}

function sortCompare(x, y) {
  return ((x < y) ? -1 : ((x > y) ? 1 : 0))
}

export { 
  utcToLocalTimeString, 
  localTimeToUtc,
  setSuccessMessage, 
  setErrorMessage, 
  cloneObject, 
  forceFileDownload,
  sortCompare,
  addHours,
  dateToString,
}
