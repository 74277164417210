import Vue from 'vue'
import { localTimeToUtc } from '@/utils'
const USERS_URL = 'Users/'

export default {
  get () {
    return Vue.http.get(USERS_URL)
      .then((response) => { return response.body })
  },

  getCurrentUser () {
    return Vue.http.get(USERS_URL + 'GetCurrentUser')
      .then((response) => { return response.body })
  },

  login (email, password) {
    return Vue.http.post(USERS_URL + 'Login', { Username: email, Password: password })
      .then((response) => { return response.body })
  },

  update (user) {
    return Vue.http.put(USERS_URL + 'Update', { Id: user.id, Email: user.email, FirstName: user.firstName, LastName: user.lastName, IsLocked: user.isLocked, UtcLockoutEnd: localTimeToUtc(user.LockoutEnd), isEmailConfirmed: user.isEmailConfirmed, isActive: user.isActive })
      .then((response) => { return response.body })
  },

  updatePassword(passwordData) {
    return Vue.http.put(USERS_URL + 'UpdatePassword', { CurrentPassword: passwordData.currentPassword, NewPassword: passwordData.newPassword, NewPasswordConfirm: passwordData.passwordConfirm })
      .then((response) => { return response.body })
  },

  updateUserPassword(user, passwordData) {
    return Vue.http.put(USERS_URL + 'UpdateUserPassword', { UserId: user.id, NewPassword: passwordData.newPassword, NewPasswordConfirm: passwordData.newPasswordConfirm })
      .then((response) => { return response.body })
  },

  sendInvite(user) {
    return Vue.http.post(USERS_URL + 'Invite', { user })
      .then((response) => { return response.body })
  },

  authRegistration(token) {
    return Vue.http.get(USERS_URL + 'AuthRegistration/' + token)
      .then((response) => { return response.body });
  },

  register(user) {
    return Vue.http.post(USERS_URL + 'Register', { Id: user.id, Email: user.email, FirstName: user.firstName, LastName: user.lastName, Password: user.password, PasswordConfirm: user.passwordConfirm })
      .then((response) => { return response.body })
  },

  disable(userId) {
    return Vue.http.put(USERS_URL + 'Disable/' + userId)
      .then((response) => { return response.body })
  },

  enable(userId) {
    return Vue.http.put(USERS_URL + 'Enable/' + userId)
      .then((response) => { return response.body })
  },

  getByUserId(userId) {
    return Vue.http.get(USERS_URL + 'GetByUserId/' + userId)
      .then((response) => { return response.body })
  },

  clearData() {
    return Vue.http.delete(USERS_URL + 'ClearData')
      .then((response) => { return response.body })
  },

  removeAccount() {
    return Vue.http.delete(USERS_URL + 'DeleteAccount')
      .then((response) => { return response.body })
  },

  delete(userId) {
    return Vue.http.delete(USERS_URL + userId)
      .then((response) => response.body)
  },

  sendForgotPasswordEmail(email) {
    return Vue.http.post(USERS_URL + 'SendForgotPasswordEmail', { Email: email })
      .then((response) => { return response })
  },

  resetPassword(passwordData) {
    return Vue.http.post(USERS_URL + 'ResetPassword', passwordData);
  },

  validateEmailToken(token) {
    return Vue.http.post(USERS_URL + 'ValidateEmailToken', { Token: token })
      .then((response) => { return response.body })
  },

  unlock(userId) {
    return Vue.http.put(USERS_URL + 'Unlock/' + userId)
      .then((response) => { return response.body })
  },

  insert(user, password) {
    return Vue.http.post(USERS_URL, { ...user, ...password})
      .then((response) => { return response.body })
  }
}
