export const login = ({ commit }, data) => {
  commit('setCurrentUser', data.user)
  commit('setToken', data.token)
}

export const logout = ({ commit }) => {
  commit('setCurrentUser', null)
}

export const updateUser = ({ commit }, user) => {
  commit('setCurrentUser', user)
}
