<template>
    <div class="ld-panel">
      <span class="ld-frame">
        <img class="ld-img" src="../assets/spinner.svg">
      </span>
      <strong>Loading...</strong>
    </div>
</template>

<script>
export default {
  name: 'LoadingPanel'
}
</script>

<style>
.ld-panel {
  background-color: white;
  z-index: 9999;
}
.ld-frame {
    height: 100%;
    vertical-align: middle;
}
.ld-img {
    vertical-align: middle;
    max-height: 40px;
    max-width: 40px;
    margin-top: -5px;
    margin-left: -5px;
}

</style>
