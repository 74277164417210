<template>
  <nav class="navbar navbar-light navbar-expand-lg bg-white border-bottom shadow-sm mb-4">
    <h5 class="navbar-brand my-0 font-weight-normal">
      Carl Calvanico CPA <span class="d-none d-sm-inline">Secure Portal</span>
    </h5>
    
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav-bar" aria-controls="nav-bar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="nav-bar">
      <div class="mr-auto"></div>
      <div class="d-none d-lg-block font-italic font-weight-light pr-5" v-if="currentUser()">Hi, {{ currentUser().firstName }} {{ currentUser().lastName }}.</div>
      <ul class="navbar-nav mt-2 mt-lg-0">
        <router-link to="/inbox" class="p-2 text-dark" v-if="!isAdminUser()">Inbox</router-link>
        <router-link to="/my-documents" class="p-2 text-dark" v-if="!isAdminUser()">My Documents</router-link>
        <router-link to="/users" class="p-2 text-dark" v-if="isAdminUser()">Users</router-link>
        <router-link to="/admin-emails" class="p-2 text-dark" v-if="isAdminUser()">Sent Emails</router-link>
        <router-link to="/user/1" class="p-2 text-dark">My Account</router-link>
        <router-link to="/admin-settings" class="p-2 text-dark" v-if="isAdminUser()">Settings</router-link>
        <router-link v-if="!isAuthenticated()" to="/login" class="btn btn-outline-primary">Login</router-link>
        <a href="#" class="btn btn-outline-primary" v-if="isAuthenticated()" v-on:click="onLogoutClick">Logout</a>
      </ul>
    </div>
  </nav>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Banner',
  
  methods: {
    ...mapGetters([
      'isAuthenticated',
      'currentUser',
      'isAdminUser'
    ]),

    ...mapActions([
      'login',
      'logout'
    ]),

    onLogoutClick: function () {
      this.logout()
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style>

</style>